import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useDeleteQuestionMutation = () => {
    const toast = useToast();
    return useMutation({
        mutationFn: async ({
            caseId,
            questionnaireId,
            questionId,
        }: {
            caseId: number;
            questionnaireId: number;
            questionId: number;
        }) => {
            return await sdk.functional.$case.questionnaires.questions.deleteQuestion(
                getApiConnection(),
                caseId,
                questionnaireId,
                questionId,
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["questions"]);
            toast({
                title: "Question deleted",
                status: "success",
            });
        },
    });
};
