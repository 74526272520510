/**
 * @packageDocumentation
 * @module api.functional.$case.questionnaires
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

import type { EnrichedQuestionnaire } from "../../../structures/EnrichedQuestionnaire";

export * as questions from "./questions";

/**
 * @controller QuestionnaireController.getQuestionnaires
 * @path GET /case/:caseId/questionnaires
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getQuestionnaires(connection: IConnection, caseId: number): Promise<getQuestionnaires.Output> {
    return PlainFetcher.fetch(connection, {
        ...getQuestionnaires.METADATA,
        template: getQuestionnaires.METADATA.path,
        path: getQuestionnaires.path(caseId),
    });
}
export namespace getQuestionnaires {
    export type Output = EnrichedQuestionnaire[];

    export const METADATA = {
        method: "GET",
        path: "/case/:caseId/questionnaires",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires`;
}

/**
 * @controller QuestionnaireController.getQuestionnaire
 * @path GET /case/:caseId/questionnaires/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getQuestionnaire(
    connection: IConnection,
    caseId: number,
    id: number,
): Promise<getQuestionnaire.Output> {
    return PlainFetcher.fetch(connection, {
        ...getQuestionnaire.METADATA,
        template: getQuestionnaire.METADATA.path,
        path: getQuestionnaire.path(caseId, id),
    });
}
export namespace getQuestionnaire {
    export type Output = EnrichedQuestionnaire;

    export const METADATA = {
        method: "GET",
        path: "/case/:caseId/questionnaires/:id",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, id: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(id ?? "null")}`;
}

/**
 * @controller QuestionnaireController.updateQuestionnaire
 * @path PATCH /case/:caseId/questionnaires/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function updateQuestionnaire(
    connection: IConnection,
    caseId: number,
    id: number,
    body: updateQuestionnaire.Input,
): Promise<updateQuestionnaire.Output> {
    return PlainFetcher.fetch(
        {
            ...connection,
            headers: {
                ...connection.headers,
                "Content-Type": "application/json",
            },
        },
        {
            ...updateQuestionnaire.METADATA,
            template: updateQuestionnaire.METADATA.path,
            path: updateQuestionnaire.path(caseId, id),
        },
        body,
    );
}
export namespace updateQuestionnaire {
    export type Input = {
        name: string;
    };
    export type Output = {
        id: number;
        name: string;
        created_at: string & Format<"date-time">;
        case_id: number;
        updated_at: string & Format<"date-time">;
        created_by_id: number;
        updated_by_id: number;
    };

    export const METADATA = {
        method: "PATCH",
        path: "/case/:caseId/questionnaires/:id",
        request: {
            type: "application/json",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, id: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(id ?? "null")}`;
}

/**
 * @controller QuestionnaireController.createQuestionnaire
 * @path POST /case/:caseId/questionnaires
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function createQuestionnaire(
    connection: IConnection,
    caseId: number,
): Promise<createQuestionnaire.Output> {
    return PlainFetcher.fetch(connection, {
        ...createQuestionnaire.METADATA,
        template: createQuestionnaire.METADATA.path,
        path: createQuestionnaire.path(caseId),
    });
}
export namespace createQuestionnaire {
    export type Output = {
        id: number;
        name: string;
        created_at: string & Format<"date-time">;
        case_id: number;
        updated_at: string & Format<"date-time">;
        created_by_id: number;
        updated_by_id: number;
    };

    export const METADATA = {
        method: "POST",
        path: "/case/:caseId/questionnaires",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number) => `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires`;
}

/**
 * @controller QuestionnaireController.deleteQuestionnaire
 * @path DELETE /case/:caseId/questionnaires/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deleteQuestionnaire(
    connection: IConnection,
    caseId: number,
    id: number,
): Promise<deleteQuestionnaire.Output> {
    return PlainFetcher.fetch(connection, {
        ...deleteQuestionnaire.METADATA,
        template: deleteQuestionnaire.METADATA.path,
        path: deleteQuestionnaire.path(caseId, id),
    });
}
export namespace deleteQuestionnaire {
    export type Output = {
        id: number;
        name: string;
        created_at: string & Format<"date-time">;
        case_id: number;
        updated_at: string & Format<"date-time">;
        created_by_id: number;
        updated_by_id: number;
    };

    export const METADATA = {
        method: "DELETE",
        path: "/case/:caseId/questionnaires/:id",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, id: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(id ?? "null")}`;
}
