import {
    Button,
    Card,
    CardBody,
    Checkbox,
    Heading,
    HStack,
    Icon,
    IconButton,
    Spacer,
    Stack,
    Text,
} from "@chakra-ui/react";
import { faTimes, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "motion/react";
import { FC, useEffect, useState } from "react";
import { ScrollBox } from "../../../components/navigation/scrollBox/ScrollBox.tsx";
import { useAcceptQuestionsMutation } from "../../../lib/mutations/useAcceptQuestionsMutation.ts";
import { useGenerateQuestionsMutation } from "../../../lib/mutations/useGenerateQuestionsMutation.ts";
import { useGeneratedQuestionsQuery } from "../../../lib/queries/useGeneratedQuestionsQuery.ts";
import { Question } from "./Question.tsx";

interface Props {
    caseId: number;
    questionnaireId: number;
    onClose: () => void;
    isOpen: boolean;
}
export const QuestionnaireSidebar: FC<Props> = ({ caseId, questionnaireId, onClose, isOpen }) => {
    const $generateQuestions = useGenerateQuestionsMutation(caseId, questionnaireId);
    const $generatedQuestions = useGeneratedQuestionsQuery(caseId, questionnaireId);
    const $acceptQuestions = useAcceptQuestionsMutation(caseId, questionnaireId);
    const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
    const allQuestionsSelected =
        selectedQuestionIds.length === $generatedQuestions.data?.length && selectedQuestionIds.length > 0;

    // Toggle a question, add or remove it from the selectedQuestionIds array
    const toggleQuestion = (questionId: number) => {
        if (selectedQuestionIds.includes(questionId))
            setSelectedQuestionIds(selectedQuestionIds.filter((q) => q !== questionId));
        else setSelectedQuestionIds([...selectedQuestionIds, questionId]);
    };

    // Toggle all questions, select or deselect all, depending on the current state
    const toggleCheckAll = () => {
        if (allQuestionsSelected) setSelectedQuestionIds([]);
        else setSelectedQuestionIds($generatedQuestions.data?.map((q) => q.id) || []);
    };

    const onAccept = async () => {
        await $acceptQuestions.mutateAsync({ questionIds: selectedQuestionIds });
        setSelectedQuestionIds([]);
    };

    // Generate questions when the sidebar is opened
    useEffect(() => {
        if (isOpen && !$generateQuestions.isLoading) $generateQuestions.mutate();
    }, [isOpen]);

    return (
        <Card
            w={450}
            ml={10}
            flex={1}
            as={motion.div}
            variants={{
                open: { opacity: 1 },
                closed: { opacity: 0 },
            }}
        >
            <CardBody flex={1} as={Stack}>
                <Stack gap={3} flex={1} justify={"start"}>
                    {/*Title, close button*/}
                    <HStack align={"start"}>
                        <Stack gap={1}>
                            <Heading as={"h3"} variant={"h3"}>
                                Suggested questions
                            </Heading>
                            <Text variant={"body2"}>Select questions to add to the questionnaire</Text>
                        </Stack>
                        <Spacer />
                        <IconButton
                            aria-label={"Close"}
                            icon={<FontAwesomeIcon icon={faTimes} />}
                            onClick={() => onClose()}
                        />
                    </HStack>
                    <Spacer height={4} flexGrow={0} />
                    <Checkbox
                        size={"md"}
                        colorScheme={"purple"}
                        isChecked={allQuestionsSelected}
                        onChange={() => toggleCheckAll()}
                        isIndeterminate={!allQuestionsSelected && selectedQuestionIds.length > 0}
                    >
                        Select all?
                    </Checkbox>
                    <ScrollBox hideScrollbar={true} flex={1} fadeColor={"#ffffff"}>
                        <Stack gap={2}>
                            <AnimatePresence>
                                {$generatedQuestions.data?.map((q) => (
                                    <motion.div
                                        initial={{ opacity: 0, translateX: -30 }}
                                        animate={{ opacity: 1, translateX: 0 }}
                                        transition={{ ease: "easeOut" }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                        key={q.id}
                                    >
                                        <Question
                                            question={q}
                                            isSelected={selectedQuestionIds.includes(q.id)}
                                            onToggle={() => toggleQuestion(q.id)}
                                            caseId={caseId}
                                            questionnaireId={questionnaireId}
                                        />
                                    </motion.div>
                                ))}
                            </AnimatePresence>
                            <Spacer />
                            <Button
                                alignSelf={"start"}
                                leftIcon={<Icon as={FontAwesomeIcon} icon={faWandMagicSparkles} />}
                                onClick={() => $generateQuestions.mutate()}
                                isLoading={$generateQuestions.isLoading}
                            >
                                Generate more
                            </Button>
                            <Spacer />
                        </Stack>
                    </ScrollBox>

                    <HStack>
                        <Spacer />
                        <Button onClick={() => onClose()}>Cancel</Button>
                        <Button
                            colorScheme={"purple"}
                            isDisabled={selectedQuestionIds.length === 0 || $generateQuestions.isLoading}
                            onClick={() => onAccept()}
                        >
                            {selectedQuestionIds.length === 0
                                ? "Select questions to add"
                                : selectedQuestionIds.length === $generatedQuestions.data?.length
                                  ? "Add all questions"
                                  : selectedQuestionIds.length === 1
                                    ? "Add question"
                                    : `Add ${selectedQuestionIds.length} questions`}
                        </Button>
                    </HStack>
                </Stack>
            </CardBody>
        </Card>
    );
};
