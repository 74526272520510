import { Button, Heading, HStack, Icon, IconButton, Spacer, Spinner, Stack, useDisclosure } from "@chakra-ui/react";
import { faArrowLeft, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, Reorder } from "motion/react";
import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { ScrollBox } from "../../components/navigation/scrollBox/ScrollBox.tsx";
import { useDeletePendingQuestionsMutation } from "../../lib/mutations/useDeletePendingQuestionsMutation.ts";
import { useQuestionnaireQuery } from "../../lib/queries/useQuestionnaireQuery.ts";
import { useQuestionsQuery } from "../../lib/queries/useQuestionsQuery.ts";
import { getQuestions } from "../../sdk/functional/$case/questionnaires/questions";
import { QuestionnaireSidebar } from "./components/QuestionnaireSidebar.tsx";
import { DefaultLayout } from "../../components/layouts/defaultLayout/DefaultLayout.tsx";
import { RefreshState } from "oidc-client-ts";
import { usePatchQuestionMutation } from "../../lib/mutations/usePatchQuestionMutation.ts";
import { Question } from "./components/Question.tsx";
import { QuestionnaireHeader } from "./components/QuestionnaireHeader.tsx";

interface Props {}
export const QuestionnairePage: FC<Props> = ({}) => {
    const { caseId } = useParams<"caseId">();
    const { questionnaireId } = useParams<"questionnaireId">();
    if (!caseId) throw new Error("No case id provided");
    if (!questionnaireId) throw new Error("No questionnaire id provided");

    const aiPanel = useDisclosure();
    const [isAnimating, setIsAnimating] = useState(false);

    const $questionnaire = useQuestionnaireQuery(parseInt(caseId), parseInt(questionnaireId));
    const $questions = useQuestionsQuery(parseInt(caseId), parseInt(questionnaireId));
    const $deletePendingQuestions = useDeletePendingQuestionsMutation(parseInt(caseId), parseInt(questionnaireId));

    const $patchQuestion = usePatchQuestionMutation();

    const [orderedQuestions, setOrderedQuestions] = useState<getQuestions.Output>([]);

    useEffect(() => {
        if (!$questions.data) return;
        setOrderedQuestions($questions.data);
    }, [$questions.data]);

    // Delete all pending on page load
    useEffect(() => {
        console.log("Deleting pending questions");
        $deletePendingQuestions.mutate();
    }, []);

    const saveNewOrder = (id: number, newIndex: number) => {
        // Update question, set order to new index
        $patchQuestion.mutate({
            caseId: parseInt(caseId),
            questionnaireId: parseInt(questionnaireId),
            questionId: id,
            index: newIndex,
        });
    };

    if ($questionnaire.isLoading || !$questionnaire.data) {
        return (
            <SideNavLayout>
                <Spinner />
            </SideNavLayout>
        );
    }

    return (
        <SideNavLayout>
            <HStack gap={0} align="stretch" flex={1}>
                <Stack gap={5} flex={1} as={motion.div} layout>
                    {/* Questionnaire header with title and back button */}
                    <QuestionnaireHeader caseId={caseId} questionnaire={$questionnaire.data} />

                    {/*Questions header + generate button*/}
                    <HStack>
                        <Heading as={"h4"} variant={"h2"}>
                            Questions
                        </Heading>
                        <Spacer />

                        <Button
                            leftIcon={<Icon as={FontAwesomeIcon} icon={faWandMagicSparkles} />}
                            onClick={() => aiPanel.onToggle()}
                            visibility={aiPanel.isOpen ? "hidden" : "visible"}
                        >
                            Generate questions
                        </Button>
                    </HStack>

                    {/* Questions */}
                    <ScrollBox hideScrollbar={true} flex={1} fadeColor={"#ffffff"} stickyBottom={false}>
                        <Stack
                            as={Reorder.Group}
                            values={$questions.data ?? []}
                            onReorder={(newData: getQuestions.Output) => setOrderedQuestions(newData)}
                        >
                            {orderedQuestions.map((question, i) => (
                                <Question
                                    key={`${question.id} ${isAnimating}`}
                                    caseId={parseInt(caseId)}
                                    questionnaireId={parseInt(questionnaireId)}
                                    question={question}
                                    onReorder={(newOrder) => saveNewOrder(question.id, newOrder)}
                                    index={i}
                                />
                            ))}
                            <Question caseId={parseInt(caseId)} questionnaireId={parseInt(questionnaireId)} />
                        </Stack>
                    </ScrollBox>
                </Stack>

                <Stack
                    alignSelf={"stretch"}
                    as={motion.div}
                    variants={{
                        open: { width: "auto", overflow: "visible", transition: { ease: "easeInOut" } },
                        closed: { width: 0, overflow: "hidden", transition: { ease: "easeInOut" } },
                    }}
                    animate={aiPanel.isOpen ? "open" : "closed"}
                    initial={"closed"}
                    overflow={"hidden"}
                    onAnimationStart={() => setIsAnimating(true)}
                    onAnimationComplete={() => setIsAnimating(false)}
                >
                    <QuestionnaireSidebar
                        caseId={parseInt(caseId)}
                        questionnaireId={parseInt(questionnaireId)}
                        onClose={() => {
                            aiPanel.onClose();
                            $deletePendingQuestions.mutate();
                        }}
                        isOpen={aiPanel.isOpen}
                    />
                </Stack>
            </HStack>
        </SideNavLayout>
    );
};
