import {
    Card,
    CardBody,
    Checkbox,
    HStack,
    Icon,
    IconButton,
    Img,
    Text,
    Textarea,
    Tooltip,
    useToast,
} from "@chakra-ui/react";
import { FC, memo, useEffect, useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAutosizeTextArea } from "../../../lib/hooks/chat/useAutosizeTextarea.ts";
import { faGripVertical, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, Reorder, useDragControls } from "motion/react";
import { useCreateQuestionMutation } from "../../../lib/mutations/useCreateQuestionMutation.ts";
import { usePatchQuestionMutation } from "../../../lib/mutations/usePatchQuestionMutation.ts";
import { getQuestions } from "../../../sdk/functional/$case/questionnaires/questions/index.ts";
import { useDeleteQuestionMutation } from "../../../lib/mutations/useDeleteQuestionMutation.ts";

interface FormData {
    question: string;
}

interface Props {
    question?: getQuestions.Output[number];
    index?: number; // 0 based
    autoFocus?: boolean;
    isSelected?: boolean;
    onToggle?: () => void;
    caseId: number;
    questionnaireId: number;
    onReorder?: (newOrder: number) => void;
}
export const Question: FC<Props> = memo(
    ({ index, question, isSelected, onToggle, autoFocus, caseId, questionnaireId, onReorder }) => {
        const textAreaRef = useRef<HTMLTextAreaElement | undefined>();
        const toast = useToast();
        const controls = useDragControls();
        const $form = useForm<FormData>({
            defaultValues: { question: question?.question ?? "" },
        });

        const $createQuestion = useCreateQuestionMutation();
        const $patchQuestion = usePatchQuestionMutation();
        const $deleteQuestion = useDeleteQuestionMutation();

        const save = async (string: string) => {
            if (!question) $createQuestion.mutate({ caseId, questionnaireId, question: string });
            else $patchQuestion.mutate({ caseId, questionnaireId, questionId: question.id, question: string });
        };

        useAutosizeTextArea(textAreaRef, $form.watch("question"));

        const onSubmit = async (data: FormData) => {
            if (data.question.trim().length < 3) {
                toast({
                    title: "Question is too short",
                    status: "error",
                });
                return;
            }
            save(data.question);
            $form.reset();
        };

        if (autoFocus && textAreaRef.current) textAreaRef.current.focus();

        const extraProps = onReorder
            ? {
                  as: Reorder.Item,
                  value: question,
                  dragControls: controls,
                  dragListener: false,
                  onDragEnd: () => {
                      if (!onReorder || index === undefined) return;
                      onReorder(index);
                  },
                  whileHover: "hover",
              }
            : question
              ? {
                    as: motion.div,
                    whileHover: "hover",
                }
              : {};

        const handleDelete = () => {
            if (!question) return;

            if (window.confirm("Are you sure you want to delete this question?")) {
                $deleteQuestion.mutate({
                    caseId,
                    questionnaireId,
                    questionId: question.id,
                });
            }
        };

        return (
            <Card size={"sm"} px={5} variant={"subtle"} {...extraProps}>
                <CardBody as={"form"} onSubmit={$form.handleSubmit(onSubmit)}>
                    <HStack>
                        {onReorder && (
                            <motion.div initial={{ opacity: 0.25 }} variants={{ hover: { opacity: 1 } }}>
                                <Icon
                                    as={FontAwesomeIcon}
                                    icon={faGripVertical}
                                    size={"lg"}
                                    boxSize={4}
                                    mr={2}
                                    onPointerDown={(e) => {
                                        controls.start(e);
                                        e.preventDefault();
                                    }}
                                    cursor={"grab"}
                                    color={"gray.500"}
                                />
                            </motion.div>
                        )}

                        {onToggle ? (
                            <Checkbox
                                size={"md"}
                                colorScheme={"purple"}
                                gap={3}
                                isChecked={isSelected}
                                onChange={onToggle}
                                flex={1}
                            >
                                {index !== undefined ? `${index + 1}. ` : ""}
                                <Text variant={"body2"}>{question?.question}</Text>
                            </Checkbox>
                        ) : (
                            <>
                                <Text alignSelf={"start"} variant={"body2"}>
                                    {index !== undefined ? `${index + 1}. ` : ""}
                                </Text>
                                <Textarea
                                    p={0}
                                    flex={1}
                                    variant={"unstyled"}
                                    resize={"none"}
                                    placeholder={"Add a question.."}
                                    {...$form.register("question", {
                                        required: true,
                                        minLength: 3,
                                    })}
                                    disabled={$form.formState.isSubmitting}
                                    ref={(e) => {
                                        if (e) textAreaRef.current = e;
                                        $form.register("question").ref(e);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key !== "Enter") return;
                                        e.preventDefault();
                                        $form.handleSubmit(onSubmit)();
                                    }}
                                    autoFocus={autoFocus}
                                />
                            </>
                        )}

                        {question?.accepted && (
                            <motion.div initial={{ opacity: 0 }} variants={{ hover: { opacity: 1 } }}>
                                <Tooltip label="Delete question" placement="top" hasArrow>
                                    <IconButton
                                        aria-label={"Delete question"}
                                        icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                        variant={"dropdown"}
                                        borderRadius={"lg"}
                                        size={"xs"}
                                        onClick={handleDelete}
                                    />
                                </Tooltip>
                            </motion.div>
                        )}

                        {question?.reasoning && (
                            <>
                                <Tooltip label={question.reasoning} placement={"top"} hasArrow>
                                    <IconButton
                                        variant={"dropdown"}
                                        borderRadius={"lg"}
                                        size={"xs"}
                                        aria-label={"Reasoning"}
                                        icon={<Img src={"/favicon.svg"} alt={"Reasoning"} boxSize={3} />}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </HStack>
                </CardBody>
            </Card>
        );
    },
);
