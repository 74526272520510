import {
    Avatar,
    Card,
    CardBody,
    Center,
    Heading,
    HStack,
    IconButton,
    Img,
    LinkBox,
    LinkOverlay,
    Spacer,
    Stack,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { FC } from "react";
import { Link } from "react-router-dom";
import QuestionnaireIcon from "../../../assets/images/questionnaireIcon.svg";
import { getQuestionnaires } from "../../../sdk/functional/$case/questionnaires";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteQuestionnaireMutation } from "../../../lib/mutations/useDeleteQuestionnaireMutation.ts";

interface Props {
    questionnaire: getQuestionnaires.Output[0];
}

export const Questionnaire: FC<Props> = ({ questionnaire }) => {
    const $deleteQuestionnaire = useDeleteQuestionnaireMutation();

    const handleDelete = (e: React.MouseEvent) => {
        e.preventDefault(); // Prevent navigation when clicking delete
        e.stopPropagation();

        if (window.confirm(`Are you sure you want to delete "${questionnaire.name}"?`)) {
            $deleteQuestionnaire.mutate({
                caseId: questionnaire.case_id,
                questionnaireId: questionnaire.id,
            });
        }
    };

    return (
        <LinkBox>
            <Card variant={"flat"}>
                <CardBody>
                    <HStack gap={6}>
                        <Center w={160} h={120} bg={"purple.100"} borderRadius={"lg"} mt={-2} ml={-2} mb={-2}>
                            <Img src={QuestionnaireIcon} h={12} />
                        </Center>
                        <Stack flexGrow={1} gap={2}>
                            <LinkOverlay
                                as={Link}
                                to={`/cases/${questionnaire.case_id}/questionnaires/${questionnaire.id}`}
                            >
                                <Heading size={"md"} noOfLines={1}>
                                    {questionnaire.name}
                                </Heading>
                            </LinkOverlay>
                            <Text variant={"body"}>{questionnaire._count.Question || "No"} questions</Text>
                            <Spacer />
                            <HStack>
                                <Avatar
                                    name={questionnaire.created_by.name}
                                    src={questionnaire.created_by.picture ?? undefined}
                                    size={"xs"}
                                />
                                <Text variant={"labelCaps"} color={"gray.600"}>
                                    Created on{" "}
                                    {DateTime.fromISO(questionnaire.created_at).toLocaleString(DateTime.DATE_MED)}
                                </Text>
                            </HStack>
                        </Stack>

                        {/* Delete questionnaire button */}
                        <Tooltip label="Delete questionnaire" placement="top" hasArrow>
                            <IconButton
                                aria-label="Delete questionnaire"
                                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                variant="dropdown"
                                borderRadius={"lg"}
                                size="xs"
                                onClick={handleDelete}
                                alignSelf={"start"}
                            />
                        </Tooltip>
                    </HStack>
                </CardBody>
            </Card>
        </LinkBox>
    );
};
