/**
 * @packageDocumentation
 * @module api.functional.$case.questionnaires.questions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
//================================================================
import type { IConnection } from "@nestia/fetcher";
import { PlainFetcher } from "@nestia/fetcher/lib/PlainFetcher";
import type { Format } from "typia/lib/tags/Format";

export * as generated from "./generated";
export * as generate from "./generate";
export * as accept from "./accept";

/**
 * @controller QuestionnaireController.deletePendingQuestions
 * @path DELETE /case/:caseId/questionnaires/:id/questions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deletePendingQuestions(connection: IConnection, caseId: number, id: number): Promise<void> {
    return PlainFetcher.fetch(connection, {
        ...deletePendingQuestions.METADATA,
        template: deletePendingQuestions.METADATA.path,
        path: deletePendingQuestions.path(caseId, id),
    });
}
export namespace deletePendingQuestions {
    export const METADATA = {
        method: "DELETE",
        path: "/case/:caseId/questionnaires/:id/questions",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, id: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(id ?? "null")}/questions`;
}

/**
 * @controller QuestionController.getQuestions
 * @path GET /case/:caseId/questionnaires/:questionnaireId/questions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function getQuestions(
    connection: IConnection,
    questionnaireId: number,
    caseId: number,
): Promise<getQuestions.Output> {
    return PlainFetcher.fetch(connection, {
        ...getQuestions.METADATA,
        template: getQuestions.METADATA.path,
        path: getQuestions.path(questionnaireId, caseId),
    });
}
export namespace getQuestions {
    export type Output = {
        id: number;
        created_at: string & Format<"date-time">;
        question: string;
        order: null | number;
        generated: boolean;
        reasoning: null | string;
        accepted: null | boolean;
        questionnaire_id: number;
    }[];

    export const METADATA = {
        method: "GET",
        path: "/case/:caseId/questionnaires/:questionnaireId/questions",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (questionnaireId: number, caseId: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(questionnaireId ?? "null")}/questions`;
}

/**
 * @controller QuestionController.createQuestion
 * @path POST /case/:caseId/questionnaires/:questionnaireId/questions
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function createQuestion(
    connection: IConnection,
    questionnaireId: number,
    caseId: number,
    data: createQuestion.Input,
): Promise<createQuestion.Output> {
    return PlainFetcher.fetch(
        {
            ...connection,
            headers: {
                ...connection.headers,
                "Content-Type": "application/json",
            },
        },
        {
            ...createQuestion.METADATA,
            template: createQuestion.METADATA.path,
            path: createQuestion.path(questionnaireId, caseId),
        },
        data,
    );
}
export namespace createQuestion {
    export type Input = {
        question: string;
    };
    export type Output = {
        id: number;
        created_at: string & Format<"date-time">;
        question: string;
        order: null | number;
        generated: boolean;
        reasoning: null | string;
        accepted: null | boolean;
        questionnaire_id: number;
    };

    export const METADATA = {
        method: "POST",
        path: "/case/:caseId/questionnaires/:questionnaireId/questions",
        request: {
            type: "application/json",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (questionnaireId: number, caseId: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(questionnaireId ?? "null")}/questions`;
}

/**
 * @controller QuestionController.deleteQuestion
 * @path DELETE /case/:caseId/questionnaires/:questionnaireId/questions/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function deleteQuestion(
    connection: IConnection,
    caseId: number,
    questionnaireId: number,
    id: number,
): Promise<deleteQuestion.Output> {
    return PlainFetcher.fetch(connection, {
        ...deleteQuestion.METADATA,
        template: deleteQuestion.METADATA.path,
        path: deleteQuestion.path(caseId, questionnaireId, id),
    });
}
export namespace deleteQuestion {
    export type Output = {
        id: number;
        created_at: string & Format<"date-time">;
        question: string;
        order: null | number;
        generated: boolean;
        reasoning: null | string;
        accepted: null | boolean;
        questionnaire_id: number;
    };

    export const METADATA = {
        method: "DELETE",
        path: "/case/:caseId/questionnaires/:questionnaireId/questions/:id",
        request: null,
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, questionnaireId: number, id: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(questionnaireId ?? "null")}/questions/${encodeURIComponent(id ?? "null")}`;
}

/**
 * @controller QuestionController.updateQuestion
 * @path PATCH /case/:caseId/questionnaires/:questionnaireId/questions/:id
 * @nestia Generated by Nestia - https://github.com/samchon/nestia
 */
export async function updateQuestion(
    connection: IConnection,
    caseId: number,
    questionnaireId: number,
    id: number,
    data: updateQuestion.Input,
): Promise<updateQuestion.Output> {
    return PlainFetcher.fetch(
        {
            ...connection,
            headers: {
                ...connection.headers,
                "Content-Type": "application/json",
            },
        },
        {
            ...updateQuestion.METADATA,
            template: updateQuestion.METADATA.path,
            path: updateQuestion.path(caseId, questionnaireId, id),
        },
        data,
    );
}
export namespace updateQuestion {
    export type Input = {
        question?: undefined | string;
        index?: undefined | number;
    };
    export type Output = {
        id: number;
        created_at: string & Format<"date-time">;
        question: string;
        order: null | number;
        generated: boolean;
        reasoning: null | string;
        accepted: null | boolean;
        questionnaire_id: number;
    };

    export const METADATA = {
        method: "PATCH",
        path: "/case/:caseId/questionnaires/:questionnaireId/questions/:id",
        request: {
            type: "application/json",
            encrypted: false,
        },
        response: {
            type: "application/json",
            encrypted: false,
        },
        status: null,
    } as const;

    export const path = (caseId: number, questionnaireId: number, id: number) =>
        `/case/${encodeURIComponent(caseId ?? "null")}/questionnaires/${encodeURIComponent(questionnaireId ?? "null")}/questions/${encodeURIComponent(id ?? "null")}`;
}
