import { RefObject, useLayoutEffect } from "react";

export const useAutosizeTextArea = (textAreaRef: RefObject<HTMLTextAreaElement | undefined>, value: string) => {
    const recalculateHeight = () => {
        if (!textAreaRef.current) return;

        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaRef.current.style.height = "0px";
        textAreaRef.current.style.minHeight = "0px";
        const scrollHeight = textAreaRef.current.scrollHeight;

        // We then set the height directly, outside of the render loop
        // Trying to set this with state or a ref will product an incorrect value.
        textAreaRef.current.style.height = scrollHeight + "px";
    };

    useLayoutEffect(recalculateHeight, [textAreaRef, value]);
};
