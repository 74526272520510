import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const usePatchQuestionnaireMutation = () => {
    const toast = useToast();
    return useMutation({
        mutationFn: async ({
            caseId,
            questionnaireId,
            name,
        }: {
            caseId: number;
            questionnaireId: number;
            name: string;
        }) => {
            return await sdk.functional.$case.questionnaires.updateQuestionnaire(
                getApiConnection(),
                caseId,
                questionnaireId,
                { name },
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["questionnaire"]);
            toast({
                title: "Questionnaire updated",
                status: "success",
            });
        },
    });
};
