import {
    Box,
    Button,
    Center,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Spacer,
    Spinner,
    Stack,
    Text,
    transition,
} from "@chakra-ui/react";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import { AnimatePresence, motion } from "motion/react";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import qnaPlaceholder from "../../assets/images/qna-placeholder.svg";
import { SideNavLayout } from "../../components/layouts/sideNavLayout/SideNavLayout.tsx";
import { ShareButton } from "../../components/members/shareButton/ShareButton.tsx";
import { EmptyState } from "../../components/misc/emptyState/EmptyState.tsx";
import { useCreateQuestionnareMutation } from "../../lib/mutations/useCreateQuestionnareMutation.ts";
import { useQuestionnairesQuery } from "../../lib/queries/useQuestionnairesQuery.ts";
import { getQuestionnaires } from "../../sdk/functional/$case/questionnaires";
import { Questionnaire } from "./components/Questionnaire.tsx";
import { useMemoQuery } from "../../lib/queries/useMemoQuery.ts";
interface Props {}
export const QuestionnairesPage: FC<Props> = ({}) => {
    const { caseId } = useParams<"caseId">();
    if (!caseId) throw new Error("No case id provided");

    const navigate = useNavigate();

    const $questionnaires = useQuestionnairesQuery(parseInt(caseId));

    const $createQuestionnaire = useCreateQuestionnareMutation();

    const createQuestionnaire = async () => {
        const questionnaire = await $createQuestionnaire.mutateAsync({ caseId: parseInt(caseId) });
        navigate(`/cases/${caseId}/questionnaires/${questionnaire.id}`);
    };

    const [query, setQuery] = useState("");

    const fuse = useMemo(
        () => new Fuse($questionnaires.data ?? ([] as getQuestionnaires.Output), { keys: ["name"] }),
        [$questionnaires.data],
    );
    const filteredQuestionnaires = query.length > 0 ? fuse.search(query).map((r) => r.item) : $questionnaires.data;

    const $memo = useMemoQuery(parseInt(caseId));

    if ($memo.isLoading) {
        return (
            <SideNavLayout>
                <Spinner />
            </SideNavLayout>
        );
    }

    return (
        <SideNavLayout>
            <Stack gap={10} flexGrow={1}>
                {/*title, share button*/}
                <HStack gap={4}>
                    <Heading as={"h1"} size={"lg"}>
                        Q&A
                    </Heading>
                    <Spacer />
                    <ShareButton variant={"full"} caseId={parseInt(caseId)} />
                </HStack>

                {/* search, new questionnaire button*/}
                <HStack gap={4}>
                    <InputGroup maxW={80} variant={"ghost"}>
                        <InputLeftElement pointerEvents="none">
                            <Icon icon={faMagnifyingGlass} as={FontAwesomeIcon} />
                        </InputLeftElement>
                        <Input variant={"gray"} placeholder={"Search Q&A"} onChange={(e) => setQuery(e.target.value)} />
                    </InputGroup>
                    <Spacer />
                    <Button
                        colorScheme={"purple"}
                        leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} />}
                        onClick={createQuestionnaire}
                        isLoading={$createQuestionnaire.isLoading}
                        isDisabled={$memo.isLoading || !$memo.data || $memo.isError}
                    >
                        New Q&A
                    </Button>
                </HStack>
                {$questionnaires.isLoading && <Spinner />}

                {/* No memo yet: go create one */}
                {$memo.isError ? (
                    <Center h="70vh">
                        <Stack maxW={500} gap={4}>
                            <Heading size={"md"}>No memo available</Heading>
                            <Text>
                                Maven generates questions based on the memo. In order to generate questions, please{" "}
                                <strong>generate a memo first</strong>
                            </Text>
                            <Button
                                alignSelf={"end"}
                                onClick={() => navigate(`/cases/${caseId}/memo`)}
                                colorScheme={"purple"}
                            >
                                Generate memo
                            </Button>
                        </Stack>
                    </Center>
                ) : // No QNA added yet
                $questionnaires.isSuccess && $questionnaires.data?.length === 0 ? (
                    <EmptyState grow imageSrc={qnaPlaceholder}>
                        No Q&A added to this case
                    </EmptyState>
                ) : // No search results
                $questionnaires.isSuccess &&
                  $questionnaires.data?.length > 0 &&
                  filteredQuestionnaires?.length === 0 ? (
                    <Center h="70vh">
                        <EmptyState>No questionnaires found</EmptyState>
                    </Center>
                ) : (
                    <Stack
                        as={motion.div}
                        variants={{ hidden: {}, visible: { transition: { staggerChildren: 0.05 } } }}
                        initial={"hidden"}
                        animate={"visible"}
                        key={filteredQuestionnaires?.length}
                    >
                        {filteredQuestionnaires?.map((questionnaire, i) => (
                            <motion.div
                                key={questionnaire.id}
                                layout
                                variants={{
                                    hidden: { opacity: 0, translateX: -10 },
                                    visible: { opacity: 1, translateX: 0 },
                                }}
                            >
                                <Questionnaire questionnaire={questionnaire} />
                            </motion.div>
                        ))}
                    </Stack>
                )}
            </Stack>
        </SideNavLayout>
    );
};
