import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import sdk from "../../sdk";
import { getApiConnection } from "../app/api.ts";
import { queryClient } from "../app/queryClient.ts";

export const useDeleteQuestionnaireMutation = () => {
    const toast = useToast();
    return useMutation({
        mutationFn: async ({ caseId, questionnaireId }: { caseId: number; questionnaireId: number }) => {
            return await sdk.functional.$case.questionnaires.deleteQuestionnaire(
                getApiConnection(),
                caseId,
                questionnaireId,
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(["questionnaires"]);
            toast({
                title: "Questionnaire deleted",
                status: "success",
            });
        },
    });
};
