import { Heading, HStack, IconButton, Input, useToast } from "@chakra-ui/react";
import { faArrowLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { usePatchQuestionnaireMutation } from "../../../lib/mutations/usePatchQuestionnaireMutation.ts";
import { getQuestionnaire } from "../../../sdk/functional/$case/questionnaires/index.ts";

interface QuestionnaireHeaderProps {
    caseId: string;
    questionnaire: getQuestionnaire.Output;
}

/**
 * QuestionnaireHeader component displays the title of the questionnaire
 * with a back button to navigate to the questionnaires list
 * and allows editing the questionnaire title
 */
export const QuestionnaireHeader: FC<QuestionnaireHeaderProps> = ({ caseId, questionnaire }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(questionnaire.name);
    const toast = useToast();

    const $patchQuestionnaire = usePatchQuestionnaireMutation();

    const handleSave = () => {
        if (title.trim().length < 3) {
            toast({
                title: "Title is too short",
                status: "error",
            });
            return;
        }

        $patchQuestionnaire.mutate({
            caseId: parseInt(caseId),
            questionnaireId: questionnaire.id,
            name: title,
        });

        setIsEditing(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSave();
        } else if (e.key === "Escape") {
            setTitle(questionnaire.name);
            setIsEditing(false);
        }
    };

    return (
        <HStack gap={4} flex={0}>
            <IconButton
                aria-label={"back button"}
                size={"xl"}
                bg={"none"}
                p={3}
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
                as={Link}
                to={`/cases/${caseId}/questionnaires`}
            />

            {isEditing ? (
                <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={handleSave}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    size="lg"
                    fontWeight="bold"
                    // variant="flushed"
                    placeholder="Enter questionnaire title"
                />
            ) : (
                <Heading as={"h1"} size={"lg"}>
                    {questionnaire.name}
                    <IconButton
                        bg={"none"}
                        aria-label={"edit title"}
                        size={"sm"}
                        ml={2}
                        icon={<FontAwesomeIcon icon={faPen} />}
                        onClick={() => setIsEditing(true)}
                    />
                </Heading>
            )}
        </HStack>
    );
};
