import { Box, Stack, StackProps } from "@chakra-ui/react";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { useStickyBottom } from "../../../lib/util/useStickyBottom.ts";

interface Props extends StackProps {
    children: ReactNode;
    onScrollBottom?: () => void;
    onScrollTop?: () => void;
    hideScrollbar?: boolean;
    fadeColor?: string;
    stickyBottom?: boolean;
}
export const ScrollBox: FC<Props> = ({
    children,
    onScrollBottom,
    onScrollTop,
    hideScrollbar,
    fadeColor,
    stickyBottom = true,
    ...props
}) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const [hasMoreTop, setHasMoreTop] = useState(false);
    const [hasMoreBottom, setHasMoreBottom] = useState(false);

    useStickyBottom(scrollContainerRef, undefined, stickyBottom);

    const onScrollInternal = () => {
        if (!scrollContainerRef.current) return;
        setHasMoreTop(scrollContainerRef.current.scrollTop > 1);
        setHasMoreBottom(
            scrollContainerRef.current.scrollHeight -
                scrollContainerRef.current.clientHeight -
                scrollContainerRef.current.scrollTop >
                1,
        );
    };

    useEffect(() => {
        if (!hasMoreTop && onScrollTop) onScrollTop();
        onScrollInternal(); // In case the callback triggered changes in children, recalculate
    }, [hasMoreTop, onScrollTop]);

    useEffect(() => {
        if (!hasMoreBottom && onScrollBottom) onScrollBottom();
        onScrollInternal(); // In case the callback triggered changes in children, recalculate
    }, [hasMoreBottom, onScrollBottom]);

    return (
        <Stack flexGrow={1} flexShrink={1} position={"relative"} overflow={"hidden"} {...props}>
            <Fader visible={hasMoreTop} fadeColor={fadeColor} />
            <Box
                flexGrow={1}
                flexShrink={1}
                height={0}
                sx={{
                    overflowY: "scroll",
                    // webkit
                    "&::-webkit-scrollbar": { width: hideScrollbar ? 0 : undefined },
                    // firefox
                    scrollbarWidth: hideScrollbar ? "none" : undefined,
                    // edge
                    msOverflowStyle: hideScrollbar ? "none" : undefined,
                }}
                onScroll={() => onScrollInternal()}
                ref={scrollContainerRef}
            >
                {children}
            </Box>
            <Fader visible={hasMoreBottom} flipped fadeColor={fadeColor} />
        </Stack>
    );
};

interface FaderProps {
    flipped?: boolean;
    visible: boolean;
    fadeColor?: string;
}
const Fader: FC<FaderProps> = ({ flipped, visible, fadeColor = "#eeeeee" }) => {
    return (
        <Box
            sx={{
                position: "absolute",
                top: flipped ? undefined : 0,
                bottom: flipped ? 0 : undefined,
                left: 0,
                right: 0,
                height: 50,
                // backgroundColor: 'grey.100',
                backgroundImage: `linear-gradient(to ${flipped ? "top" : "bottom"}, ${fadeColor} 0%, transparent 100%)`,
                zIndex: 1,
                pointerEvents: "none",
                transform: `scaleY(${visible ? 1 : 0})`,
                transformOrigin: flipped ? "bottom" : "top",
                transition: "transform .1s ease-in-out",
            }}
        />
    );
};
